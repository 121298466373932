import React from 'react'
function MainAsia() {
    return (
        <>
            <section className="experiencia">
                <h1 className="text-center experiencia__h3">Viajes Asia</h1>
                <br />
                <br />
            </section>
            <br />
            <br />
            <section className="section-top-ten">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <iframe className="iframe-mega" title="asia" src="https://q.megatravel.com.mx/tools/vi.php?Dest=4&txtColor=000&aColor=000&ahColor=2e2d2d&thBG=2e2d2d&thTxColor=fff&ff=7" width="100%" height="1200" border="0" align="left" allowtransparency="true" frameborder="0"> </iframe>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default MainAsia
